import { Queue } from "@/api/interface/queue"
import { WaitTime } from "@/api/interface/wait-time"
import { CustomField } from "@/api/interface/custom-field"

export const mapqueueselectOptions = (
  queues: Array<Queue>,
  waitTimes: Array<WaitTime>
) => {
  if (!queues || !waitTimes) return []

  const sortedQueues = queues.sort((a, b) => {
    const aIndex = a.index
    const bIndex = b.index

    if (aIndex > bIndex) {
      return 1
    }

    if (aIndex < bIndex) {
      return -1
    }

    return 0
  })

  const options = sortedQueues.map((q) => {
    const queueLocked = q.maxCapacity === 0

    const queueFull =
      q.maxCapacity > 0 &&
      waitTimes.find((y) => y.queueId === q.id)?.waitingPartiesInQueue >=
        q.maxCapacity

    let label = q.name
    if (queueFull) label += ' (Full)'
    if (queueLocked) label += ' (Closed)'

    return {
      ...q,
      value: q.id,
      label,
      disabled: queueFull || queueLocked,
    }
  })
  return options
}

export const transformValuesForApi = (values): Partial<Queue> => {
  return {
    ...values,
    maxCapacity: parseInt(values.maxCapacity) || -1,
  }
}

export const transformValuesForDisplay = (values) => {

  return {
    ...values,
    maxCapacity: values.maxCapacity === -1 ? '' : values.maxCapacity,
    waitTimeAlgCustom: values.waitTimeAlgCustom || 5,
  }
}

export const mapCustomQueueOptions = (options: CustomField["options"], waitTimes: WaitTime[]) => {
  const waitTimeQueues = waitTimes.map(x => x.queueId)

  return options?.map((x) => ({
    ...x,
    label: x.value,
    value: x.value,
    data: { 
      ...x.data, 
      icon: x.icon, 
      valueMeta: x.valueMeta, 
      queueName: waitTimes?.find(wt => wt.queueId === x.valueMeta)?.queueName
    },
  }))
    .filter(x => {
      return waitTimeQueues.includes(x.data.valueMeta)
  })
}

// Get custom queue select options from available custom fields and wait times
export const getCustomQueueSelectOptions = (customFields: CustomField[], waitTimes: WaitTime[]) => {
  const queueSelectCf = customFields?.find((cf) => cf.type === 'queueselect')
  if (queueSelectCf) {
    const { options } = queueSelectCf
    return mapCustomQueueOptions(options, waitTimes)
  }
}

export const getQueueIdsFromCustomOptions = (customQueueOptions?: CustomField['options']) => {
  return customQueueOptions?.reduce((acc, d) => {
    const { valueMeta } = d.data
    if (!acc.includes(valueMeta)) {
      acc.push(valueMeta)
    }
    return acc
  }, [])
}

// Get a list of queue select options not included in the custom queue select options
export const getStandardQueueSelectOptions = (
  queues: Queue[], 
  waitTimes: WaitTime[], 
  customQueueOptions?: CustomField['options']
): StandardQueueOption[] => {
  if (!customQueueOptions) {
    return mapqueueselectOptions(queues, waitTimes)
  }
  const customOptionQueues = getQueueIdsFromCustomOptions(customQueueOptions)
  const standardQueues = queues.filter((q) => !customOptionQueues?.includes(q.id))

  return mapqueueselectOptions(standardQueues, waitTimes)
}

export const getAllStandardQueueSelectOptions = (
  queues: Queue[], 
  waitTimes: WaitTime[], 
): StandardQueueOption[] => {
  return mapqueueselectOptions(queues, waitTimes)
}

export type StandardQueueOption = Queue & {
  value: string
  label: string
  disabled?: boolean
}

export const getShouldUseBothQueueSelectors = (
  queues: Queue[], 
  customQueueOptions: CustomField['options']
): boolean => {
  const customOptionQueues = getQueueIdsFromCustomOptions(customQueueOptions)
  const standardQueues = queues?.filter((q) => !customOptionQueues?.includes(q.id))
  return standardQueues?.length !== 0
}
